<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body container pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <v-autocomplete
                                                    v-model="user"
                                                    :items="users"
                                                    label="Khách hàng"
                                                    item-text="Name"
                                                    item-value="Id"
                                                    return-object
                                                ></v-autocomplete>
                                            </div>
                                            <div class="col-md-4" align="end" style="display: flex;justify-content: center;flex-direction: column;">
                                                <v-dialog v-model="dialog" persistent max-width="600px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn text v-bind="attrs" v-on="on">
                                                            <v-icon small class="mr-2">mdi-plus</v-icon>Thêm khách hàng
                                                        </v-btn>
                                                    </template>
                                                    <v-card>
                                                        <v-card-title>
                                                            <span class="headline">Thêm khách hàng</span>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_user_name" label="Tên KH(*)" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_user_phone" label="SĐT(*)" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_user_addr" label="Địa chỉ(*)" required></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                            <small>(*)Trường bắt buộc</small>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="blue darken-1" text @click="dialog = false">Huỷ</v-btn>
                                                            <v-btn color="blue darken-1" text @click="create_user()">Thêm</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                                <!--<v-btn @click="show_create_tag()" text><v-icon small class="mr-2">mdi-plus</v-icon>Thêm từ khoá</v-btn>-->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="user_phone"
                                            label="SĐT"
                                        ></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="user_addr"
                                            label="Địa chỉ"
                                        ></v-text-field>
                                    </div>
                                    <div class="col-md-12" v-if="product">
                                        <v-autocomplete
                                            v-model="product"
                                            :items="products"
                                            label="Sản phẩm"
                                            item-text="Name"
                                            item-value="Id"
                                            return-object
                                        ></v-autocomplete>
                                    </div>
                                    <div class="col-md-12" v-if="product_code">
                                        <v-text-field
                                            v-model="product_code"
                                            label="Sản phẩm"
                                            :disabled="true"
                                        ></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="quantity"
                                            type="number"
                                            label="Số lượng"
                                        ></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="amount"
                                            type="number"
                                            label="Tổng tiền"
                                        ></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <v-datetime-picker dateFormat="dd-MM-yyyy" label="Thời gian đặt hàng" v-model="time"> </v-datetime-picker>
                                    </div>
                                    <div class="col-md-12">
                                        <!-- <v-text-field
                                            v-model="status"
                                            label="Trạng thái"
                                            type="number"
                                        ></v-text-field> -->
                                        <v-select
                                            v-model="status"
                                            :items="data_status"
                                            label="Trạng thái"
                                            item-text="Name"
                                            item-value="Id"
                                        ></v-select>
                                    </div>
                                    <div v-if="status == 1" class="col-md-12">
                                        <v-datetime-picker dateFormat="dd-MM-yyyy" label="Thời gian giao hàng dự kiến" v-model="expected_time"> </v-datetime-picker>
                                    </div>
                                    <div v-if="status == 5" class="col-md-12">
                                        <v-textarea
                                            v-model="reason_cancel"
                                            label="Lý do huỷ"
                                        ></v-textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <v-dialog v-model="dialog_voucher" persistent max-width="400px">
                                        <v-card>
                                            <v-img
                                            v-if="selected_voucher.Banner"
                                            :src="selected_voucher.Banner"
                                            height="200px"
                                            ></v-img>
                                            <v-card-title>
                                                <span class="headline">{{ selected_voucher ? selected_voucher.Voucher_code : '' }}</span>
                                            </v-card-title>
                                            <v-card-subtitle>
                                                {{ selected_voucher ? selected_voucher.Name : '' }}
                                            </v-card-subtitle>
                                            <v-card-text>
                                                <span><strong>Thời gian chương trình:</strong> {{ format_date(selected_voucher.Start_time_active * 1000) }} - {{ format_date(selected_voucher.End_time_active * 1000) }}</span><br>
                                                <span><strong>Thời gian nhận quà:</strong> {{ format_date(selected_voucher.Start_time_claim * 1000) }} - {{ format_date(selected_voucher.End_time_claim * 1000) }}</span><br>
                                                <span><strong>Giảm giá:</strong> {{ selected_voucher.Sale_of }}</span><br>
                                                <span><strong>Tối đa:</strong> {{ selected_voucher.Sale_max }}</span><br>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="dialog_voucher = false">Đóng</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                    <div class="coupon-area">
                                        <h5>Mã khuyến mại</h5>
                                        <span v-if="order.User_id" style="font-style:italic;">Danh sách mã khuyến mại của khách hàng: {{ order.User_id.Name }} ({{ order.User_id.Phone_num }})</span>
                                        <v-card
                                            v-if="!user_voucher.length"
                                            class="my-4"
                                            max-width="100%"
                                            tile
                                        >
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Không có mã khuyến mại nào</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                        <v-card
                                            v-else
                                            class="my-4"
                                            max-width="100%"
                                            tile
                                        >
                                            <v-list-item v-for="item in user_voucher" :key="item.Id" three-line>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.Voucher_code }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.Sale_of }} {{ item.Sale_max ? ` - ${item.Sale_max}` : '' }}</v-list-item-subtitle>
                                                    <v-list-item-subtitle>{{ format_date(item.Start_time_claim * 1000) }} - {{ format_date(item.End_time_claim * 1000) }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn @click="show_voucher(item)" small>
                                                        Xem
                                                    </v-btn>
                                                    <v-btn v-if="item.Is_apply" disabled @click="confirm_apply_voucher(item)" small>
                                                        Đã sử dụng
                                                    </v-btn>
                                                    <v-btn v-else @click="confirm_apply_voucher(item)" small>
                                                        Sử dụng
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>

                                        </v-card>
                                    </div>
                                    
                                </div>
                                
                                <div class="col-md-6">
                                    <v-btn
                                        class="ma-2 float-right"
                                        :loading="loading"
                                        color="info"
                                        @click="update_order"
                                    >
                                        Sửa
                                    </v-btn>
                                    <v-btn
                                        class="ma-2 float-right"
                                        :loading="loading"
                                        @click="cancel"
                                    >
                                        Huỷ
                                    </v-btn>
                                </div>
                            </div>

                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

.coupon-area {
    padding: 12px;
}
.v-list-item:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .05);
}
.v-list-item__title {
    font-weight: 600 !important;
}
.v-list-item__action--stack {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    gap: 10px;
    margin: auto !important;
}
.v-dialog > .v-card > .v-card__subtitle {
    padding: 5px 24px 20px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Sửa đơn hàng'
    },
    data() {
        return {
            data_status: [
                { Id: 0, Name: "Chờ xác nhận" },
                { Id: 1, Name: "Đã chốt đơn" },
                { Id: 3, Name: "Đã lên đơn" },
                { Id: 5, Name: "Đã huỷ" }
            ],
            valid: true,
            id: null,
            user: {},
            url: [],
            user_phone: "",
            user_addr: "",
            quantity: 1,
            amount: 0,
            product: {},
            product_code: null,
            status: 0,
            time: new Date(),
            expected_time: null,
            reason_cancel: "",

            // for modal
            dialog: false,
            new_user_phone: "",
            new_user_name: "",
            new_user_addr: "",
            loading: false,
            name_rules: [],

            // voucher modal
            dialog_voucher: false,
            selected_voucher: {},
        };
    },
    watch: {
        user(val) {
            this.user_phone = val.Phone_num;
            this.user_addr = val.Adr1 ? val.Adr1 : val.Adr2;
        },
        product(val) {
            if(val) {
                this.amount = val.Cost * this.quantity;
            }
        },
        quantity(val) {
            this.amount = this.product.Cost * val;
        },
        order(val) {
            this.id = val.Id;
            this.user = val.User_id;
            this.product = val.Item_id;
            
            if(val.User_id) {
                if(val.User_id.Id) {
                    this.get_user_voucher(val.User_id.Id);
                }
            }

            var self = this;
            setTimeout(function() {
                self.user_phone = val.Reg_phone;
                self.user_addr = val.Reg_adr;
                self.quantity = val.Quantity;
                self.amount = val.Amount;
                self.product_code = `Sản phẩm livestream: ${val.Product_code}`;
                
                self.status = val.Status;
                self.time = new Date(val.Order_time * 1000);
                if(val.Expected_time) {
                    self.expected_time = new Date(val.Expected_time * 1000);
                }
                if(val.Delete_reason) {
                    self.reason_cancel = val.Delete_reason;
                }
            }, 1000);
            
        }

    },
    computed: {
        users() {
            return this.$store.getters['users/get_users'];
        },
        products() {
            return this.$store.getters['products/get_products'];
        },
        order() {
            return this.$store.getters['orders/get_order'];
        },
        user_voucher() {
            return this.$store.getters['promotions/get_user_voucher'];
        }
    },
    components: {
        
    },
    methods: {
        update_order() {
            this.loading = true;
            var payload = {
                Id: this.id,
                User_id: this.user.Id,
                Reg_phone: this.user_phone,
                Reg_adr: this.user_addr,
                Quantity: parseInt(this.quantity),
                Amount: this.amount,
                Order_time: Math.floor(new Date(this.time).getTime() / 1000),
                Status: this.status,
                toast: this.$root.$bvToast,
            }

            if(this.product) {
                if(this.product.Id) {
                    payload.Item_id = this.product.Id;
                }
            }

            if(this.status == 1) {
                payload.Expected_time = Math.floor(new Date(this.expected_time).getTime() / 1000);
            }
            if(this.status == 5) {
                payload.Delete_reason = this.reason_cancel;
            }
            this.$store.dispatch('orders/update_order', payload).then(() => {
                this.loading = false;
                this.$router.push({ name: 'orders-dates' })
            }).catch(() => {
                this.loading = false;
            });
        },
        create_user() {
            this.loading = true;
            var payload = {
                Phone_num: this.new_user_phone,
                Name: this.new_user_name,
                Adr1: this.new_user_addr,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('users/create_user', payload).then(() => {
                this.dialog = false;
                this.loading = false;
                this.$store.dispatch('users/get_all', {pageSize: 1000, pageIndex: 1});
            }).catch(() => {
                this.dialog = false;
                this.loading = false;
            });
        },
        cancel() {
            this.$router.go(-1);
        },
        browse_file() {
            this.$refs.upload.click();
        },
        async upload_server() {
            // var self = this;
            var files = this.$refs.upload.files;
            if(files) {
                for(var i = 0; i < files.length; i++) {
                    var url = await this.upload_one(files[i]);
                    this.url.push(url);
                }
            }
        },
        async upload_one(file) {
            var res = await this.$store.dispatch('uploads/upload', {file: file});
            return res.data.url;
        },
        delete_images() {
            this.url = [];
        },
        get_user_voucher(user_id) {
            this.$store.dispatch('promotions/get_user_voucher', {id: user_id});
        },
        format_date(date) {
            return new Date(date).toLocaleString('vi-VN', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
        },
        show_voucher(item) {
            this.selected_voucher = item;
            this.dialog_voucher = true;
        },
        confirm_apply_voucher(item) {
            if(!item) return;
            var self = this;
            Swal.fire({
                title: `Sử dụng voucher ${item.Voucher_code} cho đơn hàng này?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sử dụng',
                cancelButtonText: 'Huỷ'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.apply_voucher(item.Id);
                }
            })
        },
        async apply_voucher(voucher_id) {
            if(!voucher_id) return;
            if(!this.order) return;
            if(!this.order.User_id) return;
            if(!this.order.User_id.Id) return;

            const payload = { user_id: this.order.User_id.Id, promotion_id: voucher_id, toast: this.$root.$bvToast, };
            await this.$store.dispatch('promotions/apply_promotion', payload);
            this.get_user_voucher(this.order.User_id.Id);
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Đơn hàng", route: "/orders/index" }, { title: "Sửa đơn hàng" } ]);
        this.$store.dispatch('products/get_all', {pageSize: 1000, pageIndex: 1, categoryId: -1});
        this.$store.dispatch('users/get_all', {pageSize: 1000, pageIndex: 1});
        this.$store.dispatch('orders/get_order_by_id', {id: this.$route.params.id});
        this.$store.dispatch('promotions/reset_user_voucher');
    }
};
</script>



